import * as Sentry from '@sentry/react';
import {config} from './config';
import type {AuthUser} from './security/auth';
import {CaptureContext} from '@sentry/types';

Sentry.init({
    enabled: !!config.sentryDsn,
    dsn: config.sentryDsn,
    environment: config.sentryEnvironment,
    release: config.sentryRelease,
    ignoreErrors: [
        /Loading (CSS )?chunk \d+ failed/i,
        /^Network Error$/i,
        /^Failed to fetch$/i,
        /^NetworkError when attempting to fetch resource/i,
        /^Load failed$/i,
        /^Request failed with status code/i,
        /^Request aborted$/i,
        /^Non-Error promise rejection captured with value: Object Not Found/i,
        /zaloJSV2/i,
        /property 'javaEnabled' is a read-only/i,
    ],
    denyUrls: [
        /webkit-masked-url/i,
        /safari-web-extension/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
    ],
    tracesSampler: samplingContext => {
        if (samplingContext.location?.host.startsWith('profile')) {
            return 0.2;
        }

        return 0.01;
    },
});

export function logError(error: any, captureContext?: CaptureContext): void {
    console.error(error);
    Sentry.captureException(error, captureContext);
}

export function setSentryUser(user: AuthUser | undefined) {
    Sentry.setUser(
        user
            ? {
                  id: user.id,
                  email: user.email,
              }
            : null,
    );
}
