// import './wdyr.ts';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {register} from './serviceWorkerRegistration';
import {onServiceWorkerUpdate} from '@3m1/service-worker-updater';
import './i18n';
import './sentry';
import {ErrorBoundary} from '@sentry/react';
import Error from './pages/Error';
import './polyfill';
import MatomoAppProvider from './components/MatomoAppProvider';

window.addEventListener('vite:preloadError', event => {
    event.preventDefault();
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <ErrorBoundary fallback={({error}) => <Error error={error as Error} />}>
            <MatomoAppProvider>
                <App />
            </MatomoAppProvider>
        </ErrorBoundary>
    </React.StrictMode>,
);

register({
    onUpdate: onServiceWorkerUpdate,
});
