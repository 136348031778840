import React, {
    ImgHTMLAttributes,
    SyntheticEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import classnames from 'classnames';
import {CircularProgress} from '@mui/material';
import {config} from '../../config.ts';

type Size = {
    width: number;
    height: number;
};

type OptionalSize = {
    width?: number;
    height?: number;
};

const loadedImages: Record<string, Size> = {};

type Props = {
    src: string;
    delay?: number;
    width?: number;
    height?: number;
    minHeight?: number;
    loadingMinHeight?: number | string;
    maxHeight?: number;
    centered?: boolean;
    rounded?: boolean;
    transparent?: boolean;
    adjustHeightOnload?: boolean;
    onLoad?: () => void;
    imgProps?: ImgHTMLAttributes<HTMLImageElement>;
} & ImgHTMLAttributes<HTMLImageElement>;

export type {Props as LoadingImgProps};

export default function LoadingImg({
    src,
    width,
    height,
    rounded,
    alt,
    minHeight,
    maxHeight,
    onLoad: onLoadHandler,
    loadingMinHeight,
    delay = 300,
    adjustHeightOnload,
    centered,
    transparent,
    imgProps = {},
    ...rest
}: Props) {
    if (adjustHeightOnload && !width) {
        throw new Error(`width must be defined when using adjustHeightOnload`);
    }

    function computeHeight(
        img: Size,
        width: number,
        minHeight?: number,
        maxHeight?: number,
    ): number {
        const cH = (img.height * width!) / img.width;

        if (minHeight && cH < minHeight) {
            return minHeight;
        }
        if (maxHeight && cH > maxHeight) {
            return maxHeight;
        }

        return cH;
    }

    const cachedSize: Size | undefined = loadedImages[src];
    const [loaded, setLoaded] = useState(Boolean(cachedSize) || config.wasSsr);
    const [error, setError] = useState(false);
    const [displayLoader, setDisplayLoader] = useState(false);
    const timeout = useRef<ReturnType<typeof setTimeout> | undefined>();
    const refLoaded = useRef<string>();
    const imgRef = useRef<HTMLImageElement | null>(null);
    const defaultSize: OptionalSize =
        adjustHeightOnload && cachedSize
            ? {
                  width: width,
                  height: computeHeight(
                      cachedSize,
                      width!,
                      minHeight,
                      maxHeight,
                  ),
              }
            : {width, height};
    const [size, setSize] = useState<OptionalSize>(defaultSize);

    useEffect(() => {
        if (refLoaded.current === src) {
            return;
        }

        setLoaded(false);

        timeout.current = setTimeout(() => setDisplayLoader(true), delay);

        return () => {
            timeout.current && clearInterval(timeout.current);
        };
    }, [src]);

    useEffect(() => {
        let handled = false;
        const wakeup = () => {
            if (imgRef.current && imgRef.current.complete) {
                if (!loaded) {
                    // iOS hack when VCF is downloaded over profile
                    if (imgRef.current.width === 0) {
                        if (handled) {
                            return;
                        }
                        handled = true;
                        const im = new Image();
                        im.onload = () => {
                            handled = false;
                            setLoaded(true);
                        };
                        im.onerror = () => {
                            handled = false;
                        };
                        im.src = imgRef.current.src;
                    } else {
                        setLoaded(true);
                    }
                }
            }
        };
        const events = ['focus', 'resume'];
        events.forEach(event => window.addEventListener(event, wakeup));

        return () => {
            events.forEach(event => window.removeEventListener(event, wakeup));
        };
    }, [imgRef, loaded]);

    const onLoad = useCallback(
        (e: SyntheticEvent<HTMLImageElement>) => {
            refLoaded.current = src;
            timeout.current && clearTimeout(timeout.current);

            const target = e.target as HTMLImageElement;
            loadedImages[src] = {
                width: target.width,
                height: target.height,
            };

            if (adjustHeightOnload) {
                setSize({
                    width: width!,
                    height: computeHeight(target, width!, minHeight, maxHeight),
                });
            }
            setLoaded(true);

            onLoadHandler && onLoadHandler();
        },
        [src],
    );

    return (
        <div
            className={classnames(['img-loader'], {
                'loaded': loaded || error,
                'load-error': error,
                'overflowed': adjustHeightOnload,
                'was-long': displayLoader && !error,
                'centered': centered,
                'transparent': transparent,
                'default-hidden': !import.meta.env.SSR,
            })}
            style={{
                width: size.width || '100%',
                height: size.height || '100%',
                minHeight:
                    loadingMinHeight && !loaded ? loadingMinHeight : undefined,
            }}
            {...rest}
        >
            {!loaded && displayLoader && !error && (
                <CircularProgress color="inherit" />
            )}
            {!error && (
                <img
                    ref={imgRef}
                    src={src}
                    onLoad={onLoad}
                    onError={() => {
                        setError(true);
                    }}
                    alt={alt}
                    style={{
                        borderRadius: rounded ? '50%' : undefined,
                    }}
                    {...imgProps}
                    key={`${src}::${loaded ? 'l' : 'p'}`}
                />
            )}
        </div>
    );
}
