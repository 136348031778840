import {config} from '../config';
import {useEffect} from 'react';
import {TagManager} from '../lib/gtm/TagManager.ts';

export function useGtm() {
    useEffect(() => {
        if (config.gtmId) {
            TagManager.initialize({
                source: `${config.sstUrl}/znjwmawpqdf1tim.js`,
                idParam: 'aw',
                gtmId: config.gtmId,
                dataLayer: {
                    page: 'profile',
                },
            });
        }
    }, []);
}
