import * as React from 'react';
import {PropsWithChildren, ReactNode} from 'react';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {LinearProgress, Theme, useMediaQuery, useTheme} from '@mui/material';
import {Breakpoint, SxProps} from '@mui/system';
import {useModals} from '../../../hooks/useModalStack';

export const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    children?: React.ReactNode;
    onClose: () => void;
}

export const AppDialogTitle = (props: DialogTitleProps) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

type ActionArgs = {
    onClose: () => void;
    loading?: boolean;
};

type Props = PropsWithChildren<{
    open?: boolean | undefined;
    title?: ReactNode;
    actions?: (args: ActionArgs) => React.ReactNode;
    onClose?: () => void;
    loading?: boolean;
    maxWidth?: Breakpoint | false;
    sx?: SxProps<Theme>;
}>;

export type {Props as AppDialogProps};

export default function AppDialog({
    title,
    children,
    actions,
    loading,
    open = true,
    onClose,
    maxWidth = 'md',
    sx,
}: Props) {
    const {closeModal} = useModals();
    const progressHeight = 3;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        closeModal();
        onClose && onClose();
    };

    return (
        <BootstrapDialog
            fullScreen={fullScreen}
            onClose={handleClose}
            open={open}
            fullWidth={true}
            maxWidth={maxWidth}
            sx={sx}
        >
            {title && (
                <AppDialogTitle onClose={handleClose}>{title}</AppDialogTitle>
            )}
            <DialogContent dividers>{children}</DialogContent>
            {loading && (
                <LinearProgress
                    style={{
                        height: progressHeight,
                        marginBottom: -progressHeight,
                    }}
                />
            )}
            {actions && (
                <DialogActions>
                    {actions({
                        onClose: handleClose,
                        loading,
                    })}
                </DialogActions>
            )}
        </BootstrapDialog>
    );
}
