import {Box, CircularProgress, Drawer} from '@mui/material';
import {Profile} from '../../types/api';
import {useTranslation} from 'react-i18next';
import React, {Suspense} from 'react';
import {coverWidth} from '../../lib/style';
import {lazyWithRetry} from '../../lib/lazyWithRetry';
import {useLocation, useNavigate} from 'react-router-dom';
import {useMatomo} from '@jonkoops/matomo-tracker-react';
import {ErrorBoundary} from '@sentry/react';
import ProfileViewContent from './ProfileViewContent.tsx';

const ExchangeForm = lazyWithRetry(
    'ExchangeForm',
    () => import('./ExchangeForm'),
);

type Props = {
    profile: Profile;
    safeKey: string;
    isPreview?: boolean;
};

export default function ProfileView({
    profile,
    safeKey,
    isPreview = false,
}: Props) {
    const {t} = useTranslation('app');
    const navigate = useNavigate();
    const {search} = useLocation();
    const {trackEvent} = useMatomo();

    const track = React.useCallback(
        (open: boolean) => {
            trackEvent({
                category: 'interaction',
                action: 'toggle',
                name: 'exchange',
                value: open ? 1 : 0,
            });
        },
        [trackEvent],
    );

    const showExchangeModal = React.useCallback(() => {
        navigate('?exchange');
        track(true);
    }, [track]);
    const closeExchangeModal = React.useCallback(() => {
        navigate('', {replace: true});
        track(false);
    }, [track]);

    const displayExchangeModal = search.includes('exchange');

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                }}
            >
                <ProfileViewContent
                    profile={profile}
                    isPreview={isPreview}
                    showExchangeModal={showExchangeModal}
                />
                {profile.options?.exchanges && (
                    <Drawer
                        sx={theme => ({
                            zIndex: theme.zIndex.drawer + 2,
                        })}
                        anchor="bottom"
                        keepMounted={true}
                        open={displayExchangeModal}
                        onClose={closeExchangeModal}
                        slotProps={{
                            paper: {
                                sx: theme => ({
                                    py: {
                                        xs: 2,
                                        sm: 4,
                                    },
                                    px: {
                                        xs: 2,
                                        sm: 4,
                                    },
                                    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
                                    margin: 'auto',
                                    width: '100%',
                                    maxWidth: coverWidth,
                                }),
                            },
                        }}
                    >
                        <ErrorBoundary
                            fallback={
                                <>
                                    {t(
                                        'app:common.failed_chunk',
                                        'Failed to load, please reload the page!',
                                    )}
                                </>
                            }
                        >
                            <Suspense
                                fallback={
                                    <div
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        <CircularProgress />
                                    </div>
                                }
                            >
                                <ExchangeForm
                                    profileTitle={profile.resolvedTitle}
                                    onClose={closeExchangeModal}
                                    profileId={profile.id}
                                    profileSafeKey={safeKey!}
                                />
                            </Suspense>
                        </ErrorBoundary>
                    </Drawer>
                )}
            </Box>
        </>
    );
}
