import {createTheme, Theme} from '@mui/material';
import {styleThemes, TStyle} from './styles';
import {colorThemes, TColor} from './colors';
import baseTheme from './BaseTheme';
import {mergeDeep} from '../../../lib/merge';

const themeCache: Record<string, Theme> = {};

export function createCachedTheme(
    style: TStyle,
    color: TColor,
    direction: string,
): Theme {
    const k = `${style}:${color}:${direction}`;
    if (themeCache[k]) {
        return themeCache[k];
    }

    return (themeCache[k] = createTheme(
        mergeDeep(
            {
                cssVariables: true,
                direction,
            },
            baseTheme,
            styleThemes[style],
            colorThemes[color],
        ),
    ));
}
