import {Medium} from '../types/api';
import {StateSetter} from '../types/utils';
import {PaginatedList} from '../types/list';
import {Violation} from './form';
import {getAxiosError} from '@alchemy/api';

export function getShortId(id: string): string {
    return `#${id.substring(0, 6)}`;
}

export function getMediumTitle(medium: Medium): string {
    return medium.title ?? getShortId(medium.id);
}

export const isEmail = (email: string): boolean =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email.toLowerCase(),
    );

export function getFullName(
    firstName: string | undefined,
    lastName: string | undefined,
): string {
    return `${firstName} ${lastName}`.trim();
}

export function extractIdFromIRI(
    iri: string | null | undefined,
): string | undefined {
    if (!iri) {
        return;
    }

    return iri.replace(/^\/\w+\//, '');
}

export function createIriFromId(entity: string, id: string): string {
    return `/${entity}/${id}`;
}

export function forceObject(o: any): Record<string, any> {
    if (typeof o !== 'object' || Array.isArray(o)) {
        return {};
    }

    return o || {};
}

export function forceArray<T = any>(
    o: Record<string, T> | T[] | undefined,
): T[] {
    if (o === undefined) {
        return [];
    }
    if (!Array.isArray(o)) {
        const arr: T[] = [];

        Object.keys(o).forEach(k => {
            arr.push(o[k]);
        });

        return arr;
    }

    return o;
}

export function humanizeUrl(url: string): string {
    return url.replace(/^https?:\/\/(www\.)?/, '').replace(/\/$/, '');
}

export function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function createStateSetterProxy<T>(
    handler: T | ((prev: T) => T),
    proxy: (newState: T) => T,
): (prev: T) => T {
    return p => {
        if (typeof handler === 'function') {
            const n = (handler as (prev: T) => T)(p);

            return proxy(n);
        }

        return proxy(handler);
    };
}

export function replaceListItem<T extends {id: string}>(
    setItems: StateSetter<PaginatedList<T> | undefined>,
    itemId: string,
    item: T,
): void {
    setItems(p => {
        return {
            ...p!,
            items: p!.items.map(i => (i.id === itemId ? item : i)),
        };
    });
}

export function escapeI18nextHtml(str: string | null | undefined): string {
    if (!str) {
        return '';
    }

    return str.replace(/<\w+\s*\/?>/gi, '');
}

export function getApiViolations(e: any): string[] | undefined {
    const axiosError = getAxiosError(e);
    if (axiosError && [400, 422].includes(axiosError.code)) {
        const data = axiosError.error.response!.data as {
            violations?: Violation[];
            detail?: string;
        };
        if (data.violations) {
            return data.violations.map(v => v.title ?? v.message);
        }

        if (data.detail) {
            return [data.detail];
        }
    }
}

export function getUniqueValues<T>(arr: T[]): T[] {
    function onlyUnique(value: T, index: number, array: T[]): boolean {
        return array.indexOf(value) === index;
    }

    return arr.filter(onlyUnique);
}
