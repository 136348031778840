import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardMedia,
    Container,
    Theme,
    Typography,
} from '@mui/material';
import {Profile} from '../../types/api';
import SocialCard from './SocialCard';
import Links from './Links';
import LoadingImg from '../Ui/LoadingImg';
import {
    CloudDownload as CloudDownloadIcon,
    Link as LinkIcon,
    Repeat as RepeatIcon,
    Share as ShareIcon,
} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import React, {memo, useMemo, useState} from 'react';
import LogoWrapper from './LogoWrapper';
import {isIOS} from 'react-device-detect';
import {coverHeight, coverWidth} from '../../lib/style';
import defaultCover from '../../assets/images/default-cover-2x.jpg';
import {getBestTranslatedValue} from '../../lib/localeHelper';
import nl2br from 'react-nl2br';
import {humanizeUrl} from '../../lib/utils';
import PoweredBy from '../Ui/PoweredBy.tsx';

type Props = {
    profile: Profile;
    isPreview?: boolean;
    showExchangeModal: () => void;
};

function ProfileViewContent({
    profile,
    isPreview = false,
    showExchangeModal,
}: Props) {
    const {t} = useTranslation('app');
    const [coverScalable, setCoverScalable] = useState(false);

    const sizes = {
        xs: 80,
        md: 100,
        lg: 120,
    };

    const hasShare = Boolean(navigator.share);

    const {
        kompany,
        title: titleFallback,
        translations,
        vcfUrl,
        hideCompanyName,
        options,
        firstName: firstNameFallback,
        lastName: lastNameFallback,
        bio: bioFallback,
        locale,
        cover: profileCover,
    } = profile;

    const cover = profileCover ?? kompany?.cover;

    const title = getBestTranslatedValue(
        translations ?? {},
        'title',
        titleFallback,
        locale,
    );
    const firstName = getBestTranslatedValue(
        translations ?? {},
        'firstName',
        firstNameFallback,
        locale,
    );
    const lastName = getBestTranslatedValue(
        translations ?? {},
        'lastName',
        lastNameFallback,
        locale,
    );
    const bio = getBestTranslatedValue(
        translations ?? {},
        'bio',
        bioFallback,
        locale,
    );
    const kompanyName = kompany?.name;

    const fullName =
        (firstName
            ? `${firstName}${lastName ? ` ${lastName}` : ''}`
            : kompanyName) || '';

    const socials = [
        ...((kompany && kompany.socials) || []),
        ...(profile.socials || []),
    ];

    const links = [
        ...(kompany?.links || []).map(l => ({
            ...l,
            locale: kompany?.locale,
        })),
        ...(profile.links || []),
    ];

    const navigatorShare = (title: string): void => {
        if (!hasShare) {
            return;
        }

        navigator
            .share({
                title: `${title} - VKARD.io`,
                url: window.location.href,
            })
            .catch(e => {
                if (!e.toString().includes('AbortError')) {
                    throw e;
                }
            });
    };

    const onCoverLoad = useMemo<(() => void) | undefined>(
        () =>
            cover?.options?.scale ? () => setCoverScalable(true) : undefined,
        [setCoverScalable],
    );

    return (
        <>
            <Container
                maxWidth="sm"
                sx={{
                    padding: 0,
                }}
            >
                <Box
                    sx={theme => ({
                        padding: {
                            xs: 0,
                            sm: `0 0 ${theme.spacing(2)}`,
                        },
                    })}
                >
                    <Card>
                        <CardMedia
                            sx={{
                                'backgroundColor': '#FFF',
                                '& .MuiButton-root': {
                                    opacity: 0,
                                    transition: 'opacity 0.2s ease-in-out',
                                },
                                '&:hover .MuiButton-root': {
                                    opacity: 1,
                                },
                            }}
                            style={{position: 'relative'}}
                        >
                            <LogoWrapper
                                svgSpec={cover?.options}
                                scalable={coverScalable}
                            >
                                <LoadingImg
                                    onLoad={onCoverLoad}
                                    loadingMinHeight={`min(${coverHeight}px, ${(coverHeight / coverWidth) * 100}vw)`}
                                    src={cover?.url || defaultCover}
                                    alt={fullName}
                                />
                            </LogoWrapper>
                            {kompany?.siteUrl && (
                                <Button
                                    color={'secondary'}
                                    variant={'contained'}
                                    sx={theme => ({
                                        position: 'absolute',
                                        textTransform: 'none',
                                        bottom: theme.spacing(1),
                                        right: theme.spacing(1),
                                    })}
                                    href={kompany?.siteUrl}
                                    target={'_blank'}
                                    rel={'noopener noreferrer'}
                                    startIcon={<LinkIcon />}
                                >
                                    {humanizeUrl(kompany?.siteUrl)}
                                </Button>
                            )}
                        </CardMedia>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                p: 2,
                            }}
                        >
                            {profile.avatar && (
                                <Avatar
                                    sx={(theme: Theme) => ({
                                        width: sizes,
                                        height: sizes,
                                        marginTop: {
                                            xs: `-25px`,
                                            md: `-30px`,
                                            lg: `-30px`,
                                        },
                                        marginBottom: theme.spacing(3),
                                        border: `1px solid #FFF`,
                                        boxShadow: theme.shadows[12],
                                    })}
                                >
                                    <LoadingImg
                                        src={profile.avatar!.url}
                                        alt={fullName}
                                    />
                                </Avatar>
                            )}
                            <div
                                style={{
                                    flexGrow: 1,
                                    textAlign: 'center',
                                }}
                            >
                                {fullName ? (
                                    <Typography
                                        gutterBottom
                                        variant="h1"
                                        component="div"
                                    >
                                        {fullName}
                                    </Typography>
                                ) : null}
                                {title ? (
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        {title}
                                    </Typography>
                                ) : null}
                                {kompany && !hideCompanyName ? (
                                    <Typography
                                        variant="body1"
                                        color="text.primary"
                                    >
                                        {kompany!.siteUrl ? (
                                            <a
                                                style={{
                                                    color: 'inherit',
                                                    textDecoration: 'inherit',
                                                }}
                                                href={kompany!.siteUrl}
                                                target={'_blank'}
                                                rel="noopener noreferrer"
                                            >
                                                {kompanyName!}
                                            </a>
                                        ) : (
                                            kompanyName!
                                        )}
                                    </Typography>
                                ) : null}
                            </div>
                        </Box>

                        <CardActions
                            sx={{
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                gap: 1,
                            }}
                        >
                            {(isPreview || vcfUrl) && (
                                <Button
                                    href={vcfUrl ?? '#'}
                                    size={'large'}
                                    variant={'contained'}
                                    color={'primary'}
                                    startIcon={<CloudDownloadIcon />}
                                >
                                    {!isIOS
                                        ? t(
                                              'app:profile.save_contact',
                                              'Contact info',
                                          )
                                        : t(
                                              'app:profile.save_contact_ios',
                                              'Contact info',
                                          )}
                                </Button>
                            )}{' '}
                            {options?.exchanges && (
                                <Button
                                    onClick={
                                        !isPreview
                                            ? showExchangeModal
                                            : undefined
                                    }
                                    data-testid={'exchange'}
                                    size={'large'}
                                    variant={'contained'}
                                    color={'secondary'}
                                    startIcon={<RepeatIcon />}
                                >
                                    {t('app:profile.exchange', 'Exchange')}
                                </Button>
                            )}
                        </CardActions>

                        {(isPreview || hasShare) && (
                            <CardActions
                                sx={{
                                    justifyContent: 'center',
                                }}
                            >
                                <Button
                                    onClick={() => {
                                        navigatorShare(profile.resolvedTitle);
                                    }}
                                    size={'small'}
                                    startIcon={<ShareIcon />}
                                >
                                    {t('app:profile.share', 'Share')}
                                </Button>
                            </CardActions>
                        )}

                        {bio && (
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                    mt: 1,
                                    p: 1,
                                    textAlign: 'center',
                                }}
                            >
                                {nl2br(bio)}
                            </Typography>
                        )}

                        <div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    mt: 2,
                                }}
                            >
                                {socials.map((i, index) => (
                                    <SocialCard
                                        key={index}
                                        {...i}
                                        isPreview={isPreview}
                                    />
                                ))}
                            </Box>
                            <Links
                                links={links}
                                locale={locale}
                                isPreview={isPreview}
                            />
                        </div>
                    </Card>
                    {!isPreview && !profile.options.noBranding ? (
                        <PoweredBy profileId={profile.id} />
                    ) : (
                        ''
                    )}
                </Box>
            </Container>
        </>
    );
}

export default memo(ProfileViewContent, (a, b) => a.profile === b.profile);
