// From https://github.com/sooro-io/react-gtm-module/blob/main/src/TagManager.js
import {config} from '../../config.ts';

export type GtmProps = {
    gtmId: string;
    events?: any[];
    dataLayer?: DataLayer;
    dataLayerName?: string;
    auth?: string;
    preview?: string;
    source: string;
    idParam?: string;
};

export type DataLayer = Record<string, any> | undefined;

export const Snippets = {
    tags: function ({
        gtmId: id,
        events = [],
        dataLayer,
        dataLayerName = 'dataLayer',
        auth = undefined,
        preview = undefined,
        source,
        idParam = 'id',
    }: GtmProps) {
        const url = new URL(source);
        const environment =
            auth && preview
                ? `&gtm_auth=${auth}&gtm_preview=${preview}&gtm_cookies_win=x`
                : '';

        const iframe = `
			<iframe src="${url.origin}/ns.html?id=${id}${environment}"
				height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;

        const script = `
			(function(w,d,s,l,i){w[l]=w[l]||[];
				w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'}${
                    events!.length > 0
                        ? ',' + JSON.stringify(events).slice(1, -1)
                        : ''
                });
				var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
				j.async=true;
				j.src='${source}?${idParam}='+i.replace(/^GTM-/, '')+dl+'${environment}';
				${config.nonce ? `j.setAttribute('nonce','${config.nonce}');` : ''}
				f.parentNode.insertBefore(j,f);
			})(window,document,'script','${dataLayerName}','${id}');`;

        const dataLayerVar = this.dataLayer(dataLayer, dataLayerName!);

        return {
            iframe,
            script,
            dataLayerVar,
        };
    },
    dataLayer: function (dataLayer: DataLayer, dataLayerName: string): string {
        return `
			window.${dataLayerName} = window.${dataLayerName} || [];
			window.${dataLayerName}.push(${JSON.stringify(dataLayer)})`;
    },
};
