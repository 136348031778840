import {useState} from 'react';
import {AddToWalletFn} from '../../../services/api/ProfileApi';

type UseWalletHandlerProps = {
    profileId: string;
    safeKey: string | undefined;
    offline: boolean;
};

export default function useWalletHandler({
    profileId,
    safeKey,
    offline,
}: UseWalletHandlerProps) {
    const [walletLoading, setWalletLoading] = useState(false);

    const createWalletPassHandler = (handler: AddToWalletFn) => async () => {
        setWalletLoading(true);
        try {
            await handler(
                profileId,
                safeKey,
                offline
                    ? {
                          offline: true,
                          vcf: {
                              avatar: false,
                              links: false,
                              addresses: false,
                              note: false,
                          },
                      }
                    : {
                          offline: false,
                      },
            );
        } finally {
            setWalletLoading(false);
        }
    };

    return {walletLoading, createWalletPassHandler};
}
