import {Medium, MediumDisableReason} from '../../types/api';
import {
    clearEntityCache,
    createListResponse,
    get,
    list,
    ListResponse,
    put,
    PutFn,
    RequestOptions,
} from './CRUD';
import Api from '../../lib/apiClient';
import {AxiosRequestConfig} from 'axios';
import {RowData, SheetFilters} from '../../components/Sheet/types';
import {BatchEditInput} from './KompanyApi';
import {profileEntity, WithProfileProvision} from './ProfileApi';
import {runOrGetPromise} from '../../lib/promises';

export const mediumEntity = 'mediums';

export const listMediums = (options?: RequestOptions) =>
    list<Medium>(mediumEntity, options);

export const getMedium = (
    id: string,
    keys?: string,
    options: AxiosRequestConfig<Medium> = {},
) =>
    get<Medium>(mediumEntity, id, {
        ...options,
        params: {
            safeKey: keys,
        },
    });

export const putMedium: PutFn<Medium> = async (id, data) => {
    delete data.safeKey;

    return await put(mediumEntity, id, data);
};

export const linkMediumToAccount = async (
    id: string,
    keys: string,
): Promise<Medium> => {
    const res = (
        await Api.post(`${mediumEntity}/${id}/link`, {
            safeKey: keys,
        })
    ).data;

    clearEntityCache(mediumEntity, id);

    return res;
};

export const activateMedium = async (id: string): Promise<Medium> => {
    const res = (await Api.post(`${mediumEntity}/${id}/activate`, {})).data;

    clearEntityCache(mediumEntity, id);

    return res;
};

export const previewMediumStarsResolvedUrl = async (
    id: string,
    schemeUrl: string,
): Promise<string> => {
    const res = (
        await Api.post<{resolvedUrl: string}>(
            `${mediumEntity}/${id}/preview-stars-resolved-url`,
            {schemeUrl},
        )
    ).data;

    return res.resolvedUrl;
};

export const forceDeclareNfcWrite = async (
    id: string,
    data: {
        writtenAt: string;
        password: string | null;
        url: string | null;
    },
): Promise<void> => {
    const res = (await Api.post(`${mediumEntity}/${id}/force-nfc-write`, data))
        .data;

    clearEntityCache(mediumEntity, id);

    return res;
};

export const returnMedium = async (id: string): Promise<Medium> => {
    const res = (await Api.post(`${mediumEntity}/${id}/return`, {})).data;

    clearEntityCache(mediumEntity, id);

    return res;
};

export const resetMedium = async (id: string): Promise<Medium> => {
    const res = (await Api.post(`${mediumEntity}/${id}/reset`, {})).data;

    clearEntityCache(mediumEntity, id);

    return res;
};

type MediumBatchEditInput = {
    data: RowData[];
    batchMediumId?: string;
} & BatchEditInput;

export async function putMediumBatchEdit(data: MediumBatchEditInput): Promise<
    WithProfileProvision<{
        data: RowData[];
    }>
> {
    const r = await Api.post(`${mediumEntity}/batch-edit`, data);
    clearEntityCache(mediumEntity);
    clearEntityCache(profileEntity);

    return r.data;
}

export async function getMediumBatchEdit(
    organizationId: string,
    filters: SheetFilters,
): Promise<WithProfileProvision<ListResponse<RowData>>> {
    const r = (
        await Api.get(`${mediumEntity}/batch-edit`, {
            params: {
                ...filters,
                organizationId,
            },
        })
    ).data;

    return {
        ...createListResponse<RowData>(r),
        profileProvision: r.profileProvision,
    };
}

export const mediumDisableReasonsEntity = `medium-disable-reasons`;

export const listMediumDisableReasons = () => {
    return runOrGetPromise(`api:${mediumDisableReasonsEntity}:list`, () =>
        list<MediumDisableReason>(mediumDisableReasonsEntity),
    );
};

export function getMediumsKeys(medium: Medium): string {
    return `${medium.safeKey}${medium.activateKey ? `-${medium.activateKey}` : ''}`;
}
