import React from 'react';
import {
    Alert,
    CardContent,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    Typography,
    useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from 'react-i18next';
import {Profile} from '../../types/api';
import {
    isAndroid,
    isChrome,
    isDesktop,
    isIOS,
    isSafari,
} from 'react-device-detect';
import CircleBorder from '../Ui/CircleBorder';
import {StackedModalProps, useModals} from '../../hooks/useModalStack';
import AppDialog from '../Ui/Dialog/AppDialog';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import appleWallet from '../../assets/images/wallet/add-to-apple-wallet.png';
import googleWallet from '../../assets/images/wallet/add-to-google-wallet.png';

import useWalletHandler from './hooks/useWalletHandler';
import {
    addToAppleWallet,
    addToGoogleWallet,
} from '../../services/api/ProfileApi';

type Props = {
    profile: Profile;
    safeKey: string;
    qrOffline: string | undefined;
} & StackedModalProps;

export default function ProfileOfflineDialog({
    profile,
    safeKey,
    qrOffline,
    ...modalProps
}: Props) {
    const {closeModal} = useModals();
    const {t} = useTranslation();
    const theme = useTheme();
    const size = Math.min(innerWidth / 2, 300);
    const {walletLoading, createWalletPassHandler} = useWalletHandler({
        profileId: profile.id,
        safeKey,
        offline: true,
    });

    return (
        <>
            <AppDialog
                {...modalProps}
                maxWidth={'xs'}
                sx={{
                    '& .MuiDialogContent-root': {
                        padding: 0,
                    },
                }}
            >
                <IconButton
                    sx={theme => ({
                        position: 'absolute',
                        top: theme.spacing(1),
                        right: theme.spacing(1),
                    })}
                    onClick={() => closeModal()}
                >
                    <CloseIcon />
                </IconButton>
                <>
                    <CircleBorder color={theme.palette.secondary.main}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                padding: 4,
                            }}
                        >
                            <div
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#FFF',
                                    padding: 3,
                                    width: size,
                                    height: size,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <img
                                    style={{
                                        display: 'block',
                                        width: '68%',
                                    }}
                                    src={qrOffline}
                                    alt={'QRCode'}
                                />
                            </div>
                            <List
                                sx={{
                                    mt: 3,
                                }}
                            >
                                {(isIOS || (isDesktop && isSafari)) && (
                                    <ListItem>
                                        <ListItemButton
                                            disabled={walletLoading}
                                            onClick={createWalletPassHandler(
                                                addToAppleWallet,
                                            )}
                                        >
                                            <img
                                                style={{maxHeight: 40}}
                                                src={appleWallet}
                                                alt="Apple Wallet"
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                )}
                                {(isAndroid || (isDesktop && isChrome)) && (
                                    <ListItem>
                                        <ListItemButton
                                            disabled={walletLoading}
                                            onClick={createWalletPassHandler(
                                                addToGoogleWallet,
                                            )}
                                        >
                                            <img
                                                style={{maxHeight: 40}}
                                                src={googleWallet}
                                                alt="Google Wallet"
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                )}
                            </List>
                        </div>
                    </CircleBorder>
                    <CardContent>
                        <Typography
                            variant="h6"
                            gutterBottom
                            style={{
                                fontWeight: 'bold',
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}
                        >
                            <AirplanemodeActiveIcon sx={{mr: 1}} />{' '}
                            {t(
                                'app:profile_menu.offline.title',
                                'Offline sharing',
                            )}
                        </Typography>

                        <Typography variant="body2" paragraph>
                            {t(
                                'app:profile_menu.offline.description',
                                'With the offline version of the QR Code and Wallet, your prospects can add your contact card (VCF) directly to their phone without needing an Internet connection. Ideal for trade shows, conferences, and areas with no network coverage.',
                            )}
                        </Typography>
                        <Alert severity="warning">
                            {t(
                                'app:profile_menu.offline.warning',
                                'Interactions will not be included in your statistics, only online scans are counted.',
                            )}
                        </Alert>
                    </CardContent>
                </>
            </AppDialog>
        </>
    );
}
