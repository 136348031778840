import {configureClientAuthentication, OAuthClient} from '@alchemy/auth';
import {createHttpClient} from '@alchemy/api';
import {config} from '../config';
import axiosRetry, {isNetworkOrIdempotentRequestError} from 'axios-retry';
import {AxiosError} from 'axios';
import {topLevelDomain} from './urlHelper';

const oauthBaseUrl = `${config.apiUrl}/oauth2`;
export const oauthHttpClient = createHttpClient(oauthBaseUrl);
export const oauthClient = new OAuthClient({
    clientId: 'vkard_client',
    baseUrl: oauthBaseUrl,
    httpClient: oauthHttpClient,
    cookiesOptions: {
        domain: `.${topLevelDomain}`,
    },
});

const apiClient = createHttpClient(config.apiUrl);

configureClientAuthentication(apiClient, oauthClient);

axiosRetry(apiClient, {
    retryCondition: (error: AxiosError) =>
        isNetworkOrIdempotentRequestError(error) &&
        ![500, 400, 422, 403, 401].includes(
            error.response?.status ?? (0 as number),
        ),
});

export default apiClient;

export function getApiIdentity(prop: string | {id: string}): string {
    if (typeof prop === 'string') {
        return prop;
    }

    return prop.id;
}
