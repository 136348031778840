import {useTranslation} from 'react-i18next';
import {Address, Profile, User} from '../../../types/api';
import avatar from '../../../assets/images/sample-avatar.webp';
import logo from '../../../assets/images/sample-logo.png';
import socials from '../../../lib/socials';
import {useMemo} from 'react';
import {TFunction} from 'i18next';

type Options = {
    allSocials?: boolean;
};

export function useSampleProfile(options: Options = {}): Profile {
    const {t} = useTranslation();

    return useMemo(() => createSampleProfile(t, options), [t, options]);
}

export function createSampleProfile(
    t: TFunction,
    options: Options = {},
): Profile {
    const {allSocials} = options;

    const kompanyName = t(
        'admin:signature.preview.sample.kompanyName',
        'Acme Inc',
    );
    const sampleId = 'sample';
    const sampleUrl = 'https://vkard.io';
    const firstName = t('admin:signature.preview.sample.firstName', 'Jane');
    const lastName = t('admin:signature.preview.sample.lastName', 'Doe');

    const finalSocials: string[] = allSocials
        ? Object.keys(socials)
        : ['facebook', 'linkedin', 'twitter'];

    const socialLinks = finalSocials.map(t => ({
        type: t,
        value: sampleUrl,
    }));

    return {
        id: sampleId,
        addresses: [],
        emails: [
            {
                value: t(
                    'admin:signature.preview.sample.email',
                    'jane.doe@mail.com',
                ),
            },
        ],
        avatar: {
            id: sampleId,
            url: avatar,
        },
        phones: [
            {
                vcfTypes: ['work'],
                value: t(
                    'admin:signature.preview.sample.phone',
                    '+33424242424',
                ),
            },
        ],
        socials: socialLinks,
        permissions: {
            edit: true,
            acl: true,
            changeHolder: true,
            changeKompany: true,
            delete: true,
        },
        kompany: {
            id: sampleId,
            organizationId: sampleId,
            addresses: [
                {
                    id: sampleId,
                    address: t(
                        'admin:signature.preview.sample.address.address',
                        '1234 Main St',
                    ),
                    city: t(
                        'admin:signature.preview.sample.address.city',
                        'City',
                    ),
                    country: t(
                        'admin:signature.preview.sample.address.country',
                        'Country',
                    ),
                    state: t(
                        'admin:signature.preview.sample.address.state',
                        'State',
                    ),
                    zipCode: t(
                        'admin:signature.preview.sample.address.zip_code',
                        '12345',
                    ),
                } as Address,
            ],
            displayName: kompanyName,
            internalName: kompanyName,
            name: kompanyName,
            locale: 'en',
            siteUrl: t(
                'admin:signature.preview.sample.website',
                'https://acme.com',
            ),
            logo: {
                id: sampleId,
                thumbUrl: logo,
            },
            emails: [],
            phones: [],
            socials: socialLinks,
            links: [],
            permissions: {
                edit: true,
                acl: true,
                delete: true,
            },
            owner: {
                id: sampleId,
                email: '',
            } as unknown as User,
        },
        firstName,
        lastName,
        title: t('admin:signature.preview.sample.title', 'Junior Sales'),
        shareUrl: sampleUrl,
        appClipEnabled: false,
        enabled: true,
        manifestUrl: sampleUrl,
        vcfUrl: sampleUrl,
        resolvedTitle: `${firstName} ${lastName}`,
        resolvedEnabled: true,
        organizationId: sampleId,
        owner: {
            id: sampleId,
            email: '',
        } as unknown as User,
        locale: navigator.language,
        options: {},
        links: [],
    } as Profile;
}
