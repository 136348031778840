import {ComponentType, lazy, LazyExoticComponent} from 'react';
import {getLocalStorage} from './storage';

export function lazyWithRetry<T extends ComponentType<any>>(
    key: string,
    factory: () => Promise<{default: T}>,
): LazyExoticComponent<T> {
    const storageKey = `phbfr-${key}`;

    const ls = getLocalStorage();

    const delay = [200, 500, 1000, 2000];

    const retry = async (tryCount: number = 0): Promise<{default: T}> => {
        try {
            return await factory();
        } catch (error) {
            console.error(
                `Failed to load component ${key} #${tryCount}`,
                error,
            );
            if (tryCount >= delay.length) {
                throw error;
            } else {
                return new Promise((resolve, reject) => {
                    setTimeout(async () => {
                        try {
                            resolve(await retry(tryCount + 1));
                        } catch (error) {
                            reject(error);
                        }
                    }, delay[tryCount]);
                });
            }
        }
    };

    if (!ls) {
        return lazy<T>(retry);
    }

    return lazy<T>(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            ls.getItem(storageKey) || 'false',
        );

        try {
            const component = await retry();

            ls.setItem(storageKey, 'false');

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                // Assuming that the user is not on the latest version of the application.
                // Let's refresh the page immediately.
                const _true = 'true';
                ls.setItem(storageKey, _true);
                // Test whether the storage is allowed
                if (ls.getItem(storageKey) === _true) {
                    window.location.reload();
                }
            }

            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let's let the application crash and raise the error.
            throw error;
        }
    });
}
