import React, {Suspense} from 'react';
import LoadingDashboard from './components/Admin/LoadingDashboard';
import {lazyWithRetry} from './lib/lazyWithRetry';
import RootProfile from './components/Profile/RootProfile.tsx';
import Rtl from './Rtl';
import {
    customCustomerSubDomain,
    getLocation,
    isProfileUrl,
} from './lib/urlHelper';

const Dashboard = lazyWithRetry('Dashboard', () => import('./Dashboard'));

function App() {
    const l = getLocation();

    if (
        isProfileUrl(l.toString()) ||
        (customCustomerSubDomain &&
            l.pathname.match(
                /^\/p\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\/[^/]{12,}$/,
            ))
    ) {
        return (
            <Rtl>
                <RootProfile />
            </Rtl>
        );
    } else {
        return (
            <>
                <Suspense fallback={<LoadingDashboard />}>
                    <Dashboard />
                </Suspense>
            </>
        );
    }
}

export default App;
