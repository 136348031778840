import ProfileShow from '../../pages/Profile/ProfileShow.tsx';
import GlobalNotFoundPage from '../../pages/GlobalNotFoundPage.tsx';
import {getProfile} from '../../services/api/ProfileApi.ts';
import {customCustomerSubDomain} from '../../lib/urlHelper.ts';
import {RouteObject} from 'react-router-dom';
import {useRouteError} from 'react-router';
import {isErrorOfCode} from '@alchemy/api';
import MediumDisabled from '../../pages/Profile/MediumDisabled.tsx';
import Error from '../../pages/Error.tsx';
import {createSampleProfile} from '../Admin/Signature/useSampleProfile.ts';
import {TFunction} from 'i18next';

const loader = ({request, params}) => {
    return getProfile(params.id, params.safeKey, {
        'signal': request.signal,
        'params': {
            subdomain: customCustomerSubDomain,
        },
        'anonymous': true,
        'axios-retry': {
            retries: 0,
        },
    });
};

function ErrorBoundary() {
    const error = useRouteError();

    if (
        isErrorOfCode(error, [404, 403]) ||
        (error as Error).toString().match(/status\s+code\s+40[43]/)
    ) {
        return <MediumDisabled />;
    }

    console.log('error', error);

    return <Error error={error as Error} />;
}

const shouldRevalidate = ({currentUrl, nextUrl}) => {
    return currentUrl.pathname !== nextUrl.pathname;
};

export const profileRoutes: RouteObject[] = [
    {
        path: `/:id/:safeKey`,
        Component: ProfileShow,
        loader,
        ErrorBoundary,
        shouldRevalidate,
    },
    {
        path: `/p/:id/:safeKey`,
        Component: ProfileShow,
        loader,
        ErrorBoundary,
        shouldRevalidate,
    },
    {
        path: `/healthcheck`,
        Component: ProfileShow,
        loader: () =>
            createSampleProfile(((_key: string, defaultValue) =>
                typeof defaultValue === 'string'
                    ? defaultValue
                    : (defaultValue?.defaultValue ?? '')) as TFunction),
        ErrorBoundary,
    },
    {
        path: `*`,
        Component: GlobalNotFoundPage,
    },
];
