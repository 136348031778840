import React, {PropsWithChildren} from 'react';
import {adminTheme} from '../../components/Admin/AdminTheme';
import {ThemeProvider} from '@mui/material';
import {AppGlobalStyles} from '../../style.tsx';

export default function AdminLayout({children}: PropsWithChildren<{}>) {
    return (
        <ThemeProvider theme={adminTheme}>
            {AppGlobalStyles}
            {children}
        </ThemeProvider>
    );
}
