import {PropsWithChildren} from 'react';
import {SVGSpec} from '../Admin/Form/Picture/SVGPlacer';
import {coverHeight} from '../../lib/style';
import {Box} from '@mui/material';

type Props = PropsWithChildren<{
    svgSpec?: SVGSpec | undefined;
    scalable?: boolean;
}>;

export default function LogoWrapper({
    children,
    svgSpec,
    scalable = true,
}: Props) {
    return (
        <Box
            sx={{
                backgroundColor: svgSpec?.bgColor,
                img: {
                    maxHeight: coverHeight,
                },
            }}
        >
            <div
                style={{
                    transform:
                        svgSpec?.scale && scalable
                            ? `scale(${Math.round(svgSpec.scale) / 100})`
                            : undefined,
                    width: '100%',
                    height: '100%',
                }}
            >
                {children}
            </div>
        </Box>
    );
}
