// From https://github.com/sooro-io/react-gtm-module/blob/main/src/Snippets.js
import {DataLayer, GtmProps, Snippets} from './Snippet.ts';
import {config} from '../../config.ts';

export const TagManager = {
    dataScript: function (
        dataLayer: string,
        dataLayerName: string | undefined = 'dataLayer',
    ): HTMLScriptElement {
        const script = document.createElement('script');
        script.innerHTML = dataLayer;
        script.setAttribute('data-testid', dataLayerName!);
        if (config.nonce) {
            script.setAttribute('nonce', config.nonce);
        }
        return script;
    },
    gtm: function (args: GtmProps) {
        const snippets = Snippets.tags(args);

        const noScript = () => {
            const noscript = document.createElement('noscript');
            noscript.innerHTML = snippets.iframe;
            return noscript;
        };

        const script = () => {
            const script = document.createElement('script');
            script.innerHTML = snippets.script;
            if (config.nonce) {
                script.setAttribute('nonce', config.nonce);
            }
            return script;
        };

        const dataScript = this.dataScript(
            snippets.dataLayerVar,
            args.dataLayerName,
        );

        return {
            noScript,
            script,
            dataScript,
        };
    },
    initialize: function ({
        gtmId,
        source,
        idParam,
        events = [],
        dataLayer,
        dataLayerName = 'dataLayer',
        auth,
        preview,
    }: GtmProps) {
        const gtm = this.gtm({
            gtmId,
            events,
            dataLayer,
            dataLayerName,
            auth,
            preview,
            source,
            idParam,
        });
        if (dataLayer) document.head.appendChild(gtm.dataScript);
        document.head.insertBefore(gtm.script(), document.head.childNodes[0]);
        document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
    },
    dataLayer: function ({
        dataLayer,
        dataLayerName = 'dataLayer',
    }: {
        dataLayer: DataLayer;
        dataLayerName?: string;
    }) {
        if (window[dataLayerName]) return window[dataLayerName].push(dataLayer);
        const snippets = Snippets.dataLayer(dataLayer, dataLayerName);
        const dataScript = this.dataScript(snippets, dataLayerName);
        document.head.insertBefore(dataScript, document.head.childNodes[0]);
    },
};
