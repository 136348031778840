import {useEffect, useMemo, useState} from 'react';
import {Profile} from '../../types/api';
import {
    getProfileQrCodeUrl,
    logProfileView,
} from '../../services/api/ProfileApi';
import ProfileView from '../../components/Profile/ProfileView';
import {ThemeProvider} from '@mui/material';
import {createCachedTheme} from '../../components/Ui/themes/ProfileTheme';
import MediumDisabled from './MediumDisabled';
import ProfileMenu from '../../components/Profile/ProfileMenu';
import {useTranslation} from 'react-i18next';
import {getLocalStorage} from '../../lib/storage';
import {useMatomo} from '@jonkoops/matomo-tracker-react';
import ModalStack from '../../hooks/useModalStack';
import {useGtm} from '../../components/useGtm';
import {useLoaderData} from 'react-router-dom';
import {useParams} from '@alchemy/navigation';
import {AppGlobalStyles} from '../../style.tsx';
import {Helmet} from 'react-helmet';

export default function ProfileShow() {
    const {safeKey} = useParams();
    const profile = useLoaderData() as Profile;
    const {id} = profile;
    const [error, _setError] = useState<number>();
    const {t, i18n} = useTranslation('app');
    const {trackPageView} = useMatomo();

    useEffect(() => {
        trackPageView();
    }, []);

    useEffect(() => {
        getLocalStorage()?.removeItem('i18nextLng');
    }, []);

    const theme = useMemo(() => {
        return createCachedTheme(
            profile?.resolvedTheme?.style ?? 'default',
            profile?.resolvedTheme?.color ?? 'default',
            i18n.dir(),
        );
    }, [profile, i18n]);

    useEffect(() => {
        logProfileView(profile.id);
        if (
            profile.vcfUrl &&
            new URLSearchParams(window.location.search).get('vcf')
        ) {
            setTimeout(() => {
                document.location.href = profile.vcfUrl!;
            }, 1500);
        }
    }, [id]);

    const qrOnlineUrl = getProfileQrCodeUrl(id!, safeKey, {
        offline: false,
    });
    const qrOfflineUrl = getProfileQrCodeUrl(id!, safeKey, {
        offline: true,
        vcf: {
            avatar: false,
            note: false,
            links: false,
            addresses: false,
        },
    });

    useEffect(() => {
        const preLoadImage = (url: string) => {
            const img = new Image();
            img.src = url;
        };
        preLoadImage(qrOnlineUrl);
        preLoadImage(qrOfflineUrl);
    }, [id]);

    useGtm();

    if (error && [404, 403].includes(error)) {
        return <MediumDisabled />;
    }

    if (profile && profile.expired) {
        return <MediumDisabled />;
    }

    return (
        <ThemeProvider theme={theme}>
            {AppGlobalStyles}
            <Helmet encodeSpecialCharacters={false}>
                <title>{profile.resolvedTitle}</title>
                <link rel="manifest" href={profile.manifestUrl} />
                <meta property="og:title" content={profile.resolvedTitle} />
                <meta
                    property="og:description"
                    content={
                        profile.ogDescription ??
                        t(
                            'app:og.default_description',
                            'Get my contact details on VKARD',
                        )
                    }
                />
                <meta property="og:type" content="profile" />
                {profile.ogImageUrl ? (
                    <meta property="og:image" content={profile.ogImageUrl} />
                ) : null}
            </Helmet>
            <ModalStack>
                <ProfileMenu
                    profile={profile}
                    safeKey={safeKey!}
                    qrOnline={qrOnlineUrl}
                    qrOffline={qrOfflineUrl}
                />
                <ProfileView profile={profile} safeKey={safeKey!} />
            </ModalStack>
        </ThemeProvider>
    );
}
