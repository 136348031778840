import React from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {
    createStaticRouter,
    StaticHandlerContext,
    StaticRouterProvider,
} from 'react-router-dom/server';
import {profileRoutes} from './ProfileRoutes.tsx';
import '../../assets/styles/profile.scss';

type Props = {
    ssrContext?: StaticHandlerContext;
};

export default function RootProfile({ssrContext}: Props) {
    if (ssrContext) {
        return (
            <StaticRouterProvider
                router={createStaticRouter(profileRoutes, ssrContext)}
                context={ssrContext}
            />
        );
    }

    return <RouterProvider router={createBrowserRouter(profileRoutes)} />;
}
