import type {Config} from '../types/config.ts';

export type RequestContext = {
    config: Config;
    location: Location;
    navigator: Navigator;
};

export function getRequestContext(): RequestContext {
    return (
        typeof window !== 'undefined' ? window : global.requestContext
    ) as RequestContext;
}
